<template>
  <div class="profile-wrapper">

  </div>
</template>

<script>
export default {
  name: 'Profile-component'
}
</script>

<style scoped>
.profile-wrapper {
  height: 840px;
  width: 97%;
  background: #FFFFFF;
  border-radius: 36px;
  border: none;
  margin-top: 107px;
}
@media only screen and (max-height: 900px) {
  .profile-wrapper{
    height: 565px;
  }
}
</style>
