import { render, staticRenderFns } from "./CustomTextArea.vue?vue&type=template&id=56ba0160&scoped=true&"
import script from "./CustomTextArea.vue?vue&type=script&lang=js&"
export * from "./CustomTextArea.vue?vue&type=script&lang=js&"
import style0 from "./CustomTextArea.vue?vue&type=style&index=0&id=56ba0160&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56ba0160",
  null
  
)

export default component.exports