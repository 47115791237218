<template>
  <div class="docs-wrapper">

  </div>
</template>

<script>
export default {
  name: 'Docs-component'
}
</script>

<style scoped>
.docs-wrapper {
  height: 840px;
  width: 97%;
  background: #FFFFFF;
  border-radius: 36px;
  border: none;
  margin-top: 107px;
}
@media only screen and (max-height: 900px) {
  .docs-wrapper{
    height: 565px;
  }
}
</style>
